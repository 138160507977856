import React from 'react'

export type ContentProps = {
    usePaddingV?: boolean
    usePaddingH?: boolean
    useMaxWidth?: boolean
    expanded?: boolean
    variant?: 'white' | 'transparent'
    flex?: 'row' | 'column'
} & React.HTMLAttributes<HTMLDivElement>

export const Content: React.FC<ContentProps> = React.memo(
    ({
        usePaddingV,
        usePaddingH,
        useMaxWidth,
        expanded,
        children,
        variant = 'white',
        flex,
        ...rest
    }) => {
        return (
            <div
                {...rest}
                style={{
                    backgroundColor: variant,
                    paddingLeft: usePaddingH ? 'var(--content-padding)' : 0,
                    paddingRight: usePaddingH ? 'var(--content-padding)' : 0,
                    paddingTop: usePaddingV ? 'var(--content-padding)' : 0,
                    paddingBottom: usePaddingV ? 'var(--content-padding)' : 0,
                    maxWidth: useMaxWidth ? 'var(--content-width)' : undefined,
                    width: expanded ? '100%' : undefined,
                    display: flex ? 'flex' : undefined,
                    flexDirection: flex,
                    ...rest.style,
                }}
            >
                {children}
            </div>
        )
    },
)

Content.displayName = 'Content'

export default Content
